/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
		 

		var flag = $.cookie("mons_user_loc");
		$(".nav-icons ul").prepend('<li id="change-region" class="change-region"><i class="fa fa-lg flag ' + flag + '"></i><span class="change-region-text">CHANGE REGION</span></li>');

	$('.navbar-toggle').bigSlide({
			'menuWidth': '260px'
		}); 
		
		//$('#product-search').on('show.bs.collapse', function () {
		//$('.navbar-fixed-top, .navbar-toggle').addClass('search-active');
		//});
		//$('#product-search').on('hide.bs.collapse', function () {
		//$('.navbar-fixed-top, .navbar-toggle').removeClass('search-active');
		//});

		var bLazy = new Blazy({ 
		breakpoints: [{
		  width: 480 // max-width
		, src: 'data-src-small'
		}
		, {
		  width: 960 // max-width
		, src: 'data-src-medium'
		}, {
		  width: 1440 // max-width
		, src: 'data-src-large'
		}, {
		  width: 9999 // max-width
		, src: 'data-src-xl'
		}],

		});

		
			CoverPop.start({
			onPopUpOpen:         function() {
			$(".navbar").css( "display", "none" );
			}
			});
			$("#lpu-nz").click(function() {
			$.cookie('mons_user_loc', 'nz', {expires: 365, domain: '.monsroyale.com', path: '/'});
			$.cookie('_CoverPop', 'true', {expires: 365, domain: '.monsroyale.com', path: '/' });
			window.location.href = "https://brand.monsroyale.com/nz/" + window.location.pathname.slice(3) + window.location.search;
			});
			$("#lpu-au").click(function() {
			$.cookie('mons_user_loc', 'au', {expires: 365, domain: '.monsroyale.com', path: '/' });
			$.cookie('_CoverPop', 'true', {expires: 365, domain: '.monsroyale.com', path: '/' });
			window.location.href = "https://brand.monsroyale.com/au/" + window.location.pathname.slice(3) + window.location.search;
			});
			$("#lpu-us").click(function() {
			$.cookie('mons_user_loc', 'us', {expires: 365, domain: '.monsroyale.com', path: '/' });
			$.cookie('_CoverPop', 'true', {expires: 365, domain: '.monsroyale.com', path: '/' });
			window.location.href = "https://brand.monsroyale.com/us/" + window.location.pathname.slice(3) + window.location.search;
			});
			$("#lpu-ca").click(function() {
			$.cookie('mons_user_loc', 'ca', {expires: 365, domain: '.monsroyale.com', path: '/' });
			$.cookie('_CoverPop', 'true', {expires: 365, domain: '.monsroyale.com', path: '/' });
			window.location.href = "https://brand.monsroyale.com/ca/" + window.location.pathname.slice(3) + window.location.search;
			console.log( "https://brand.monsroyale.com/ca/" + window.location.pathname.slice(3) + window.location.search );
			});
			$("#lpu-eu").click(function() {
			$.cookie('mons_user_loc', 'eu', {expires: 365, domain: '.monsroyale.com', path: '/' });
			$.cookie('_CoverPop', 'true', {expires: 365, domain: '.monsroyale.com', path: '/' });
			window.location.href = "https://brand.monsroyale.com/eu/" + window.location.pathname.slice(3) + window.location.search;
			});
			$("#lpu-ch").click(function() {
			$.cookie('mons_user_loc', 'ch', {expires: 365, domain: '.monsroyale.com', path: '/' });
			$.cookie('_CoverPop', 'true', {expires: 365, domain: '.monsroyale.com', path: '/' });
			window.location.href = "https://brand.monsroyale.com/ch/" + window.location.pathname.slice(3) + window.location.search;
			});
			$('nav').on('click', '#change-region', function() {
			$("#CoverPop-cover").css( "display", "block" );
			$(".navbar").css( "display", "none" );
			});

      },
      finalize: function() {

      }
    },
    // Home page
		'home': {
		init: function() {
		var wdwidth = $( window ).width();
		var bannerheight = wdwidth / 2.4;
		$( ".home-banner" ).height( bannerheight );
		$( window ).resize(function() {
		var wdwidth = $( window ).width();
		var bannerheight = wdwidth / 2.4;
		$( ".home-banner,.home-banner-img" ).height( bannerheight );
		});
		
	  $(".home-banner").slick({
			speed: 1200,
			autoplay: true,
			autoplaySpeed: 2500,
			dots: true,
			prevArrow: '<div class="slick-arrow slick-prev" aria-label="previous"></div>',
			nextArrow: '<div class="slick-arrow slick-next" aria-label="next"></div>',
			customPaging: function(slider, i) {
						var count = i + 1;
						return '<a>0'+count+'</a>';
					}
		});
		
		$('.slick-list').nextUntil('.wrap').wrapAll('<div class="container slide-controls" />');
		$('.slick-arrow').wrapAll('<div class="slick-arrows" />'); 
		$('.home-banner').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		  var bLazy = new Blazy(),
				activeSlide = document.querySelector('.slick-active img');
			if (activeSlide.classList.contains('b-loaded')) {
				//do nothing
			} else {
				bLazy.load( activeSlide );
			}
		});

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Products page
    'products': {
      init: function() {

      },
      finalize: function() {
	  $(document).on('facetwp-loaded', function() {
        $('.product-image-slider').slick({
		  slidesToShow: 1,
		  slidesToScroll: 1,
		  arrows: false,
		  fade: true,
		  dots: true,
		  lazyLoad: 'ondemand',
		  customPaging:  function(slider, i) {
		  var colour = $(slider.$slides[i]).data('colour');
		  return '<div class="prod-nav-button ' + colour + '"></div>';
		                },
		});
		});
		$("#filters-toggle").click(function() {

			$("#filters-toggle-wrap").toggleClass( "toggled" );

			$("#filter-chev").toggleClass( "glyphicon-plus" );

			$("#filter-chev").toggleClass( "glyphicon-remove" );

		});
      }
    },
	 'our_home': {
      init: function() {

   $window = $(window);
   
   	var wdheight = $( window ).height();
	$( "section" ).css( "min-height", height+'px' );
	$( window ).resize(function() {
	$( "section" ).css( "min-height", height+'px' );g
	});
 
   $('section[data-type="background"]').each(function(){
     // declare the variable to affect the defined data-type
     var $scroll = $(this);
 
      $(window).scroll(function() {
        // HTML5 proves useful for helping with creating JS functions!
        // also, negative value because we're scrolling upwards                            
        var yPos = -($window.scrollTop() / $scroll.data('speed'));
 
        // background position
        var coords = '50% '+ yPos + 'px';
 
        // move the background
        $scroll.css({ backgroundPosition: coords });   
      }); // end window scroll
	  });  // end section function
      },
      finalize: function() {
	  }
	 }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
